
import excel_data from '../js/excel.json';

const is_need_translate = (value)=>{
  if(!value)return false;
  if(!isNaN(value))return false;
  return true;
};

const numToStr = (value)=>{
  let str = [];
  while(value >= 0){
    str.push(String.fromCharCode(65 + value % 26) );
    if(value < 26){
      value = -1;
    }else{
      value = (value - value % 26)/26 - 1;
    }
  }
  str.reverse();
  return str.join('');
};



const excel_test = {
  ready(){
    return new Promise((resolve)=>{
      resolve();
    });
  },
  run(fn){
    fn();
  },
  async goto(id){
    if(!id) throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
  },
  async replace(id,new_text){
    if(!id) throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    else console.log(new_text);
  },
  async get_paragraphs(){
    return {
      items:excel_data.filter(i=>is_need_translate(i.src)).map((i,j)=>{
        i.id = j;
        i.text = i.src;
        return i;
      })
    };
  },
  async get_paragraph_html(paragraph){
    return {
      value:paragraph.ori_html
    };
  },
};

let excel_context = null;
const excel = {
  ready(){
    return new Promise((resolve)=>{
      window.Office.onReady((info) => {
        if (info.host === window.Office.HostType.Excel) {
          resolve();
        }
      });
    });
  },
  run(fn){
    window.Excel.run(async (context) => {
      excel_context = context;
      await fn();
    });
  },
  async get_sheets(){
    let context = excel_context;
    let sheets = context.workbook.worksheets;
    sheets.load("items/name");
    await context.sync();
    return sheets.items;
  },
  async get_sheet_data(name){
    let context = excel_context;
    let sheet = context.workbook.worksheets.getItem(name);
    //sheet.activate();
    context.workbook.comments.load();
    sheet.load("name");
    await context.sync();
    let data = sheet.getUsedRange();
    data.load();
    await context.sync();
    sheet.tables.load();
    sheet.shapes.load();
    sheet.slicers.load();
    sheet.comments.load();
    sheet.charts.load();
    data.format.load();
    let cell = sheet.getCell(43,1);
    cell.load();
    await context.sync();

    for (let index = 0; index < sheet.charts.items.length; index++) {
      const chart = sheet.charts.items[index];
      chart.title.load();
      chart.series.load();
      try {
        await context.sync();
      } catch (error) {
        console.log(error);
      }
    }

    let value_shapes = [];
    for (let index = 0; index < sheet.shapes.items.length; index++) {
      const shape = sheet.shapes.items[index];
      shape.textFrame.textRange.load();
      try {
        await context.sync();
        value_shapes.push({
          index:index,
          text:shape.textFrame.textRange.text
        });
      } catch (error) {
        console.log(error);
      }
    }
    

    let value_cells = [];
    
    data.values.forEach((row,row_index)=>{
      row.forEach((cell_value,column_index)=>{
        if(cell_value !== ""){
          value_cells.push({
            row: data.rowIndex + row_index,
            column: data.columnIndex + column_index,
            address:numToStr(data.columnIndex + column_index) + (data.rowIndex + row_index),
            value:cell_value
          });
        }
      });
    });

    /* 
    columnCount
    columnIndex
    rowCount
    rowIndex
    */
    return {
      cells:value_cells,
      shapes:value_shapes,
    };
  },
  async get_active_sheet(){
    let context = excel_context;
    let sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.load("name");
    await context.sync();
    return sheet;
  },
  async set_active_sheet(name){
    let context = excel_context;
    let sheet = context.workbook.worksheets.getItem(name);
    sheet.activate();
    sheet.load("name");
    await context.sync();
    return sheet;
  },

  async goto(id){
    let context = excel_context;
    context.document.body.paragraphs.load();
    await context.sync();
    let control = context.document.body.paragraphs.items.find(i=>i._Id == id);
    if(control){
      control.select();
      context.sync();
    }else{
      throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    }
  },
  async replace(id,new_text){
    let context = excel_context;
    context.document.body.paragraphs.load();
    await context.sync();
      let control = context.document.body.paragraphs.items.find(i=>i._Id == id)
      if(control){
      control.insertHtml(new_text, window.Excel.InsertLocation.replace);
      await context.sync();
    }else{
      throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    }
  },
  async get_paragraphs(){
    let context = excel_context;
    await context.sync();
    let paragraphs = context.document.body.paragraphs;
    paragraphs.load();
    await context.sync();
    return (paragraphs);
  },
  async get_paragraph_html(paragraph){
    let context = excel_context;
    let html_control = paragraph.getHtml();
    await context.sync();
    return (html_control);
  },
};




export default process.env.VUE_APP_DEBUG == '1'?excel_test:excel;
