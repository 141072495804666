
import word_data from '../js/word.json';


const is_need_translate = (value) => {
  if (!value) return false;
  if (!isNaN(value)) return false;
  return true;
};

const word_test = {
  ready() {
    return new Promise((resolve) => {
      resolve();
    });
  },
  run(fn) {
    fn();
  },
  async goto(id) {
    if (!id) throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
  },
  async replace(id, new_text) {
    if (!id) throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    else console.log(new_text);
  },
  async get_paragraphs() {
    return {
      items: word_data.filter(i => is_need_translate(i.src)).map((i, j) => {
        i.id = j;
        i.text = i.src;
        return i;
      })
    };
  },
  async get_paragraph_html(paragraph) {
    return {
      value: paragraph.ori_html
    };
  },
};

let word_context = null;

const word = {
  ready() {
    return new Promise((resolve) => {
      window.Office.onReady((info) => {
        if (info.host === window.Office.HostType.Word) {
          console.log('infohost', info.host)
          resolve();
        }
      });
    });
  },
  run(fn) {
    window.Word.run(async (context) => {
      word_context = context;
      console.log('word_context', word_context)
      await fn();
    });
  },
  async goto(id) {
    let context = word_context;
    context.document.body.paragraphs.load();
    await context.sync();
    let control = context.document.body.paragraphs.items.find(i => i._Id == id);
    if (control) {
      control.select();
      context.sync();
    } else {
      throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    }
  },
  async replace(id, new_text) {
    let context = word_context;
    context.document.body.paragraphs.load();
    await context.sync();
    let control = context.document.body.paragraphs.items.find(i => i._Id == id)
    if (control) {
      control.insertHtml(new_text, window.Word.InsertLocation.replace);
      await context.sync();
    } else {
      throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    }
  },
  async get_paragraphs() {
    let context = word_context;
    await context.sync();
    let paragraphs = context.document.body.paragraphs;
    paragraphs.load();
    await context.sync();
    return (paragraphs);

  },
  async get_paragraph_html(paragraph) {
    let context = word_context;
    let html_control = paragraph.getHtml();
    await context.sync();
    return (html_control);
  },

  async get_paragraph_html_order(id) {
    let context = word_context;
    let  items = context.document.body.paragraphs.getFirst()
    for(let i=0;i<id;i++){
      items = items.getNext()
    }
    let html_control = items.getHtml();
    await context.sync();
    return (html_control);
  }
};




export default process.env.VUE_APP_DEBUG == '1' ? word_test : word;
