import Axios from '../index';

export function GetTagCategoryList(){
  return Axios.get(`/addin/get_tag_category_list`);
}

export function GetTagListByCategory(option){
  return Axios.get(`/addin/get_tag_list_by_category?category_guid=${option.category_guid}`);
}

export function GetCodeValueList(option){
  return Axios.get(`/addin/get_code_value_list?code_schema=${option.code_schema}`);
}

export function GetTranslateList(option){
  return Axios.post(`/addin/get_translate_list`,option);
}