<template>
  <div>
    <b-container v-show="!load_form_data">
      <b-row>
        <b-col md="auto">{{init_err_msg || '正在初始化页面，请稍后'}}</b-col>
      </b-row>
    </b-container>
    <b-container v-show="load_form_data && !init&& !show_order ">
      <b-row v-if="!doing">
        <b-col md="auto">
          <b-form @submit="onSubmit" @reset="onReset">
            <b-form-checkbox
              v-model="form.reget"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >重新抓取文本</b-form-checkbox>

            <b-form-group label="源语言:" label-for="source_language" style="margin-top: 10px;">
              <b-form-select
                id="source_language"
                v-model="form.source_language"
                :options="languages"
                class="form-control"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group label="目标语言:" label-for="target_language" style="margin-top: 10px;">
              <b-form-select
                id="target_language"
                v-model="form.target_language"
                :options="languages"
                class="form-control"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group
              style="margin-top: 10px;"
              v-for="(category) in categorys"
              :key="`category-${category.value}`"
              :label="category.text"
              :label-for="`category-${category.value}`"
            >
              <b-form-select
                :id="`category-${category.value}`"
                class="form-control"
                v-model="form[category.value]"
                :options="category.tags"
              ></b-form-select>
            </b-form-group>
            <b-button
              :disabled="doing"
              type="submit"
              variant="primary"
              style="margin-top:10px"
              id="btn1"
            >提取文本</b-button>
            <b-button
              :disabled="doing"
              type="submit"
              variant="primary"
              style="margin-top:10px;margin-left:20px"
              id="btn2"
            >顺序提取</b-button>
            <b-button
              :disabled="doing"
              type="reset"
              variant="danger"
              style="margin-top:10px;margin-left:20px"
            >重置</b-button>
          </b-form>
          <br />
          <br />
        </b-col>
      </b-row>
      <b-row v-if="doing">
        <b-col md="auto">
          <div class="task-process">
            <b-badge :variant="step_status[step1]">{{step_status_name[step1]}}</b-badge>1，初始化word文档信息
          </div>
          <div class="task-process">
            <b-badge :variant="step_status[step2]">{{step_status_name[step2]}}</b-badge>2，开始抓取文本，进度
            <b-progress :max="step2_total" v-if="step2 > 1" style="width:200px;position:relative;">
              <b-progress-bar :value="step2_count">
                <div
                  style="position:absolute;width:100%;height:100%;text-align:center;"
                >{{ step2_count }} / {{ step2_total }}</div>
              </b-progress-bar>
            </b-progress>
          </div>
          <div class="task-process">
            <b-badge :variant="step_status[step3]">{{step_status_name[step3]}}</b-badge>3，开始翻译，进度
            <b-progress :max="step3_total" v-if="step3 > 1" style="width:200px;">
              <b-progress-bar :value="step3_count">{{ step3_count }} / {{ step3_total }}</b-progress-bar>
            </b-progress>
          </div>
          <div class="task-process">
            <b-badge :variant="step_status[step4]">{{step_status_name[step4]}}</b-badge>4，格式化数据
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-show="load_form_data && init && show_list&&!show_order">
      <b-row>
        <b-col
          md="auto"
          style="display:flex;justify-content: space-between;align-items: center;width:100%;"
        >
          <div>
            <b-button variant="primary" @click="changeSearch">修改条件</b-button>&nbsp;
            <b-button variant="primary" @click="replaceAll">替换已确认的文本</b-button>
          </div>

          <div style="margin-right:20px;">
            <b-dropdown id="dropdown-1" variant="primary" text="段落" class="m-md-2">
              <b-dropdown-item @click="listAll">所有段落</b-dropdown-item>
              <b-dropdown-item @click="listYes">已匹配段落</b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-table
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            small
          >
            <template #cell(status)="data">
              <b-badge v-if="data.item.status == 1" variant="success">已确认</b-badge>
              <b-badge v-if="data.item.status != 1" variant="secondary">未确认</b-badge>
            </template>
            <template #cell(src)="data">{{data.text}}</template>
            <template #cell(tool)="data">
              <b-button
                variant="primary"
                small
                @click="edit(data.item.id)"
                style="white-space: nowrap;"
              >编辑</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="display: flex;align-items: center;">
          <b-form-input type="number" v-model="currentPage"></b-form-input>
          <div style="white-space: nowrap;margin-left:5px;">/ {{total_page}}</div>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="首页"
          prev-text="上一页"
          next-text="下一页"
          last-text="最后一页"
        ></b-pagination>
      </div>
    </b-container>

    <b-container v-show="load_form_data && init && !show_list">
      <b-row>
        <b-col md="12">
          <div style="display:flex;justify-content: space-between;">
            <b-button variant="secondary" @click="gotolist">返回列表</b-button>
            <div>
              <b-button
                variant="secondary"
                :disabled="item_index <= 0"
                @click="edit(item_index - 1)"
              >上一个</b-button>
              &nbsp;
              {{item_index + 1}} / {{items.length}}
              &nbsp;
              <b-button
                variant="secondary"
                :disabled="item_index >= items.length - 1"
                @click="edit(item_index + 1)"
              >下一个</b-button>
            </div>
            <div>
              <b-button variant="primary" @click="save">保存</b-button>&nbsp;
              <b-button variant="primary" @click="savereplace">保存并替换</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <br />
          <b-form-group label="原文:" label-for="txt_src">
            <b-form-textarea id="txt_src" v-model="item_data.src" rows="3"></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <b-button variant="secondary" @click="gotoword">定位</b-button>&nbsp;
          <b-button variant="secondary" @click="replaceword">替换</b-button>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <h3>预览效果：</h3>
          <div v-html="buildTreeNode(item_data.tree_nodes)"></div>
        </b-col>
        <b-col md="12">
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>文本</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(node,node_index) in item_data.html_nodes"
                :key="`node-${node_index}`"
                :style="node.text?'':'display:none;'"
              >
                <b-td>{{node.text}}</b-td>
                <b-form-input
                  :disabled="!node.text"
                  v-model="node.translate"
                  placeholder="请输入翻译的内容"
                ></b-form-input>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" v-if="item_data.translate">
          <h3>全文翻译：</h3>
          {{item_data.translate}}
        </b-col>
        <b-col md="12" v-if="item_data.translate_list && item_data.translate_list.length">
          <div class="partTranslate">
            <h3>部分翻译：</h3>
            <h3>客户：{{item_data.customer}}</h3>
            <h3>客户部门：{{item_data.customer_dept}}</h3>
            <b-button variant="secondary" @click="replaceword">替换</b-button>
          </div>
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>原文</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(translate,translate_index) in item_data.translate_list"
                :key="`translate-${translate_index}`"
              >
                <b-td>{{translate.source_content}}</b-td>
                <b-td>{{translate.target_content}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-show="show_order">
      <b-row>
        <b-col md="12">
          <div style="display:flex;justify-content: space-between;">
            <b-button variant="secondary" @click="order_gotolist">返回列表</b-button>
            <div>
              <b-button
                variant="secondary"
                :disabled="order_item_index >=order_paragraphs.items.length-1"
                @click="order_find(order_item_index + 1)"
              >下一个</b-button>
            </div>
            <div>
              <b-button variant="primary" @click="order_save">保存</b-button>&nbsp;
              <b-button variant="primary" @click="order_savereplace">保存并替换</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <br />
          <b-form-group label="原文:" label-for="txt_src">
            <b-form-textarea id="txt_src" v-model="order_item_data.src" rows="3"></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <b-button variant="secondary" @click="order_gotoword">定位</b-button>&nbsp;
          <b-button variant="secondary" @click="order_replaceword">替换</b-button>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <h3>预览效果：</h3>
          <div v-html="buildTreeNode(order_item_data.tree_nodes)"></div>
        </b-col>
        <b-col md="12">
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>文本</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(node,node_index) in order_item_data.html_nodes"
                :key="`node-${node_index}`"
                :style="node.text?'':'display:none;'"
              >
                <b-td>{{node.text}}</b-td>
                <b-form-input
                  :disabled="!node.text"
                  v-model="node.translate"
                  placeholder="请输入翻译的内容"
                ></b-form-input>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" v-if="order_item_data.translate">
          <h3>全文翻译：</h3>
          {{item_data.translate}}
        </b-col>

        <b-col
          md="12"
          v-if="order_item_data.translate_list && order_item_data.translate_list.length"
        >
          <div class="partTranslate">
            <h3>部分翻译：</h3>
            <h3>客户：{{order_item_data.customer}}</h3>
            <h3>客户部门：{{order_item_data.customer_dept}}</h3>
            <b-button variant="secondary" @click="order_replaceword">替换</b-button>
          </div>
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>原文</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(translate,translate_index) in order_item_data.translate_list"
                :key="`translate-${translate_index}`"
              >
                <b-td>{{translate.source_content}}</b-td>
                <b-td>{{translate.target_content}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
//import word_data from '../js/word.json';
import { GetTagListByCategory, GetCodeValueList } from "../api/common/index";
import { mapState } from "vuex";
import translate from "../js/translate.js";
import ppt from "../js/ppt.js";

export default {
  name: "office-word",
  created() {
    this.init_form_data()
      .then(() => {
        this.load_form_data = true;
      })
      .catch(() => {
        this.init_err_msg = "加载失败，请关闭插件，重新打开插件";
      });
    ppt
      .ready()
      .then(() => {
        console.log("加载成功");
      })
      .catch(() => {
        console.log("加载失败");
      });
  },
  data() {
    return {
      space: [],
      order_item_index: 0,
      order_item_data: {
        src: "",
        html: "",
        translate: "",
        control: null,
        translate_list: [],
        html_nodes: [],
        tree_nodes: []
      },
      order_items: [],
      order_words: [],
      order_pagedata: [],
      order_paragraphs: { items: [] },
      show_order: false,
      load_form_data: false,
      init_err_msg: "",
      form: {
        reget: 1,
        source_language: "",
        target_language: ""
      },
      languages: [],
      categorys: [],

      debug: process.env.VUE_APP_DEBUG == "1",
      doing: false,
      init: false,
      step_status: {
        1: "secondary",
        2: "info",
        3: "success",
        4: "danger"
      },
      step_status_name: {
        1: "待执行",
        2: "执行中",
        3: "执行成功",
        4: "执行失败"
      },
      step1: 1,
      step2: 1,
      step2_count: 30,
      step2_total: 110,
      step3: 1,
      step3_count: 30,
      step3_total: 110,
      step4: 1,

      show_list: true,
      item_index: 0,
      perPage: 20,
      currentPage: 1,
      fields: [
        {
          key: "status",
          label: "状态",
          thStyle: "width:100px;"
        },
        {
          key: "text",
          label: "原文",
          thStyle: "min-width:100px;"
        },
        {
          key: "translate",
          label: "翻译",
          thStyle: "min-width:100px;"
        },
        {
          key: "tool",
          label: " ",
          thStyle: "width:100px;"
        }
      ],
      items: [],
      item_data: {
        src: "",
        html: "",
        translate: "",
        control: null,
        translate_list: [],
        html_nodes: [],
        tree_nodes: []
      }
    };
  },

  computed: {
    ...mapState("app", ["language", "category"]),
    rows() {
      return this.items.length;
    },
    total_page() {
      return Math.ceil(this.items.length / this.perPage);
    }
  },
  methods: {
    listAll() {
      if (this.space != "") {
        this.items = this.space;
      }
    },
     listYes() {
      this.space = this.items;
      this.items = this.items.filter(item => {
        return item.translate != "" || item.translate_list.length != 0;
      });
      console.log("itmems",this.items)
    },
    buildTreeNode(tree_nodes) {
      const _build = node => {
        if (node.children.length == 0) {
          return (
            node.begin +
            node.text_before +
            (node.translate || node.text) +
            node.text_after +
            node.end
          );
        } else {
          return (
            node.begin + node.children.map(i => _build(i)).join("") + node.end
          );
        }
      };
      return tree_nodes.map(i => _build(i)).join("");
    },

    order_gotolist() {
      this.init = false;
      this.show_order = false;
    },

    alert(msg) {
      this.$bvToast.toast(msg, {
        title: "系统提示",
        autoHideDelay: 5000
      });
    },
    async init_form_data() {
      let languages = await GetCodeValueList({ code_schema: "language" });
      this.languages = [
        {
          text: "请选择...",
          value: ""
        },
        ...languages.platform_code_value.map(i => {
          return {
            text: i.name,
            value: i.value
          };
        })
      ];
      let categorys = this.$store.state.app.category;
      // let categorys = await GetTagCategoryList();
      this.categorys = categorys.get_tag_category_list.map(i => {
        return {
          text: i.name,
          value: i.category_guid
        };
      });
      for (let index = 0; index < this.categorys.length; index++) {
        const category = this.categorys[index];
        this.form[category.value] = "";
        let tags = await GetTagListByCategory({
          category_guid: category.value
        });
        category.tags = [
          {
            text: "全部",
            value: ""
          },
          ...tags.get_tag_list_by_category.map(i => {
            return {
              text: i.tag_schema,
              value: i.tag_guid
            };
          })
        ];
      }
    },

    onSubmit(e) {
      const button = event.submitter;
      e.returnValue = false;
      if (button.id == "btn1") {
        this.getPptData();
      } else if (button.id == "btn2") {
        this.order_find();
      }
    },
    onReset() {
      this.form.target_language = "";
      this.form.source_language = "";
      for (let index = 0; index < this.categorys.length; index++) {
        const category = this.categorys[index];
        this.form[category.value] = "";
      }
    },
    gotoword() {},
    order_gotoword() {},
    replaceword() {},

    order_replaceword() {},

    replaceAll() {},

    gotolist() {
      this.show_list = true;
    },

    savereplace() {},

    order_savereplace() {},
    save() {},
    order_save() {},

    changeSearch() {
      this.form.reget = "0";
      this.init = false;
    },
    getPptData() {
      this.doing = true;
      this.step1 = 1;
      this.step2 = 1;
      this.step3 = 1;
      this.step4 = 1;
      this.step2_count = 0;
      this.step2_total = 0;
      this.step3_count = 0;
      this.step3_total = 0;
      // let $ = window.$;
      this.step1 = 2;

      var list;
      ppt.run(async () => {
        if (this.form.reget == "1") {
          //如果需要重新抓取文本
          this.items = [];
          let res = await ppt.get_textRange();
          list = res;
          //2，开始抓取文本
          this.step2 = 2;
          this.step2_total = res.length;
          this.step3_total = res.length;
          this.step2 = 3;
        }

        this.step3 = 2;
        let tags = [];
        for (let index = 0; index < this.categorys.length; index++) {
          const category = this.categorys[index];
          if (this.form[category.value]) {
            tags.push(this.form[category.value]);
          }
        }
        let words = await translate({
          source_language: this.form.source_language,
          target_language: this.form.target_language,
          tags: tags
        });
        words = words.words;
        // const constomer = this.categorys[0];
        // const customer_dept = this.categorys[1];
        let regalpha = /^\w+$/g;
 
        for (let i = 0; i < list.length; i++) {
          //当前数据是否有匹配的翻译
          let text = list[i].text;
          let translate_list = words.filter(w => {
            let lowerText = text.toLowerCase();
            let sourceContent = w.source_content
              ? w.source_content.toLowerCase()
              : "";
            return lowerText.includes(sourceContent);
          });
          translate_list = translate_list.filter(w => {
            if (regalpha.test(w.source_content)) {
              //不是中文
              return new RegExp(`\\b${w.source_content}\\b`, "gi").test(text);
            } else {
              //是中文
              return true;
            }
          });
  
          let translate_word = translate_list.find(
            w => w.source_content == text
          );
          console.log("translate_word", translate_word);

          list[i].translate_list = translate_list;
          list[i].translate = translate_word?.target_content||''
          this.items.push(list[i]);
          this.step3_count += 1;
        }

        
        this.step3 = 3;

        this.doing = false;
        this.init = true;
      });
    }
  }
};
</script>

<style scoped>
.task-process {
  display: flex;
  align-items: center;
}
.partTranslate {
  display: flex;
  justify-content: space-between;
}
</style>
