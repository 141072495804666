import {GetTranslateList} from '../api/common/index';

const translate_data = {};

const translate = async (form)=>{
  let key = `${form.source_language}-${form.target_language}-${form.tags.join('')}`;
  if(translate_data[key]){
    return translate_data[key];
  }else{
    translate_data[key] = await GetTranslateList({
      source_language:form.source_language,
      target_language:form.target_language,
      tags:form.tags
    });
    return translate_data[key];
  }
};

export default translate;