<template>
  <div>
    <b-container v-show="!load_form_data">
      <b-row>
        <b-col md="auto">{{init_err_msg || '正在初始化页面，请稍后'}}</b-col>
      </b-row>
    </b-container>
    <b-container v-show="load_form_data && !init&& !show_order ">
      <b-row v-if="!doing">
        <b-col md="auto">
          <b-form @submit="onSubmit" @reset="onReset">
            <b-form-checkbox
              v-model="form.reget"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >重新抓取文本</b-form-checkbox>

            <b-form-group label="源语言:" label-for="source_language" style="margin-top: 10px;">
              <b-form-select
                id="source_language"
                v-model="form.source_language"
                :options="languages"
                class="form-control"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group label="目标语言:" label-for="target_language" style="margin-top: 10px;">
              <b-form-select
                id="target_language"
                v-model="form.target_language"
                :options="languages"
                class="form-control"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group
              style="margin-top: 10px;"
              v-for="(category) in categorys"
              :key="`category-${category.value}`"
              :label="category.text"
              :label-for="`category-${category.value}`"
            >
              <b-form-select
                :id="`category-${category.value}`"
                class="form-control"
                v-model="form[category.value]"
                :options="category.tags"
              ></b-form-select>
            </b-form-group>
            <b-button
              :disabled="doing"
              type="submit"
              variant="primary"
              style="margin-top:10px"
              id="btn1"
            >提取文本</b-button>
            <b-button
              :disabled="doing"
              type="submit"
              variant="primary"
              style="margin-top:10px;margin-left:20px"
              id="btn2"
            >顺序提取</b-button>
            <b-button
              :disabled="doing"
              type="reset"
              variant="danger"
              style="margin-top:10px;margin-left:20px"
            >重置</b-button>
          </b-form>
          <br />
          <br />
        </b-col>
      </b-row>
      <b-row v-if="doing">
        <b-col md="auto">
          <div class="task-process">
            <b-badge :variant="step_status[step1]">{{step_status_name[step1]}}</b-badge>1，初始化word文档信息
          </div>
          <div class="task-process">
            <b-badge :variant="step_status[step2]">{{step_status_name[step2]}}</b-badge>2，开始抓取文本，进度
            <b-progress :max="step2_total" v-if="step2 > 1" style="width:200px;position:relative;">
              <b-progress-bar :value="step2_count">
                <div
                  style="position:absolute;width:100%;height:100%;text-align:center;"
                >{{ step2_count }} / {{ step2_total }}</div>
              </b-progress-bar>
            </b-progress>
          </div>
          <div class="task-process">
            <b-badge :variant="step_status[step3]">{{step_status_name[step3]}}</b-badge>3，开始翻译，进度
            <b-progress :max="step3_total" v-if="step3 > 1" style="width:200px;">
              <b-progress-bar :value="step3_count">{{ step3_count }} / {{ step3_total }}</b-progress-bar>
            </b-progress>
          </div>
          <div class="task-process">
            <b-badge :variant="step_status[step4]">{{step_status_name[step4]}}</b-badge>4，格式化数据
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-show="load_form_data && init && show_list&&!show_order">
      <b-row>
        <b-col
          md="auto"
          style="display:flex;justify-content: space-between;align-items: center;width:100%;"
        >
          <div>
            <b-button variant="primary" @click="changeSearch">修改条件</b-button>&nbsp;
            <b-button variant="primary" @click="replaceAll">替换已确认的文本</b-button>
          </div>

          <div style="margin-right:20px;">
            <b-dropdown id="dropdown-1" variant="primary" text="段落" class="m-md-2">
              <b-dropdown-item @click="listAll">所有段落</b-dropdown-item>
              <b-dropdown-item @click="listYes">已匹配段落</b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-table
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            small
          >
            <template #cell(status)="data">
              <b-badge v-if="data.item.status == 1" variant="success">已确认</b-badge>
              <b-badge v-if="data.item.status != 1" variant="secondary">未确认</b-badge>
            </template>
            <template #cell(src)="data">{{data.item.src}}</template>
            <template #cell(translate)="data">{{data.item.translate}}</template>
            <template #cell(tool)="data">
              <b-button
                variant="primary"
                small
                @click="edit(data.index)"
                style="white-space: nowrap;margin-left:10px;"
              >编辑</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="display: flex;align-items: center;">
          <b-form-input type="number" v-model="currentPage" style="width:100px;"></b-form-input>
          <div style="white-space: nowrap;margin-left:5px;">/ {{total_page}}</div>
        </div>
        <b-pagination
          size="sm"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="首页"
          prev-text="上一页"
          next-text="下一页"
          last-text="最后一页"
        ></b-pagination>
      </div>
    </b-container>
    <b-container v-show="load_form_data && init && !show_list">
      <b-row>
        <b-col md="12">
          <div style="display:flex;justify-content: space-between;">
            <b-button
              variant="secondary"
              @click="gotolist"
              size="sm"
              style="font-size:12px"
            >返回文本段落列表</b-button>
            <div>
              <b-button
                variant="secondary"
                size="sm"
                :disabled="item_index <= 0"
                @click="edit(item_index - 1)"
                style="font-size:12px"
              >上一段文本</b-button>
              &nbsp;
              {{item_index + 1}} / {{items.length}}
              &nbsp;
              <b-button
                size="sm"
                variant="secondary"
                :disabled="item_index >= items.length - 1"
                @click="edit(item_index + 1)"
                style="font-size:12px"
              >下一段文本</b-button>
            </div>
            <div>
              <b-button variant="primary" @click="save" size="sm" style="font-size:12px">保存</b-button>&nbsp;
              <b-button
                variant="primary"
                @click="savereplace"
                size="sm"
                style="font-size:12px"
              >保存并替换</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <br />
          <b-form-group label="提取到的文字:" label-for="txt_src">
            <b-form-textarea id="txt_src" v-model="item_data.src" rows="3"></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <b-button variant="secondary" @click="gotoword">定位</b-button>&nbsp;
          <b-button variant="secondary" @click="replaceword">替换</b-button>
        </b-col>

        <b-col md="12">
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>文本</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(node,node_index) in item_data.html_nodes"
                :key="`node-${node_index}`"
                :style="node.text?'':'display:none;'"
              >
                <b-td>{{node.text}}</b-td>
                <b-form-input
                  :disabled="!node.text"
                  v-model="node.translate"
                  placeholder="请输入翻译的内容"
                ></b-form-input>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" v-if="item_data.translate">
          <h3>全文翻译：</h3>
          {{item_data.translate}}
        </b-col>
        <b-col md="12" v-if="item_data.translate_list && item_data.translate_list.length">
          <div class="partTranslate">
            <h3 style="font-size:22px;font-weight:700">匹配翻译的结果：</h3>
          </div>
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th style="width: 30% !important;">提取到的文字</b-th>
                <b-th style="width: 30% !important;">翻译</b-th>
                <b-th style="width: 20% !important;white-space:nowrap;">客户/客户部门</b-th>
                <b-th style="width: 20% !important;">&nbsp;&nbsp;操作</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(translate,translate_index) in item_data.translate_list"
                :key="`translate-${translate_index}`"
              >
                <b-td style="width: 30% !important; height:100%;">{{translate.source_content}}</b-td>
                <b-td style="width: 30% !important; height:100%;">{{translate.target_content}}</b-td>
                <b-td style="width: 20% !important; height:100%;">
                  <b-badge variant="info">{{translate.customer}}</b-badge>
                  <b-badge
                    variant="info"
                    style="white-space: pre-wrap;margin-top:10px"
                  >{{translate.customer_dept}}</b-badge>
                </b-td>
                <b-td style="width:20%;position: relative;">
                  <b-button
                    variant="secondary"
                    @click="part_replace(translate)"
                    size="sm"
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                  >替换</b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <h3>预览效果：</h3>
          <div v-html="buildTreeNode(item_data.tree_nodes)"></div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-show="show_order">
      <b-row>
        <b-col md="12">
          <div style="display:flex;justify-content: space-between;">
            <b-button variant="secondary" @click="order_gotolist">返回文本段落列表</b-button>
            <div>
              <b-button
                variant="secondary"
                :disabled="order_item_index >=order_paragraphs.items.length-1"
                @click="order_find(order_item_index + 1)"
              >下一段文本</b-button>
            </div>
            <div>
              <b-button variant="primary" @click="order_save">保存</b-button>&nbsp;
              <b-button variant="primary" @click="order_savereplace">保存并替换</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <br />
          <b-form-group label="提取到的文字:" label-for="txt_src">
            <b-form-textarea id="txt_src" v-model="order_item_data.src" rows="3"></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <b-button variant="secondary" @click="order_gotoword">定位</b-button>&nbsp;
          <b-button variant="secondary" @click="order_replaceword">替换</b-button>
        </b-col>

        <b-col md="12">
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>文本</b-th>
                <b-th>翻译</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(node,node_index) in order_item_data.html_nodes"
                :key="`node-${node_index}`"
                :style="node.text?'':'display:none;'"
              >
                <b-td>{{node.text}}</b-td>
                <b-form-input
                  :disabled="!node.text"
                  v-model="node.translate"
                  placeholder="请输入翻译的内容"
                ></b-form-input>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" v-if="order_item_data.translate">
          <h3>全文翻译：</h3>
          {{item_data.translate}}
        </b-col>

        <b-col
          md="12"
          v-if="order_item_data.translate_list && order_item_data.translate_list.length"
        >
          <div class="partTranslate">
            <h3>匹配翻译的结果：</h3>
          </div>
          <b-table-simple striped>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th style="width: 30% !important;">提取到的文字</b-th>
                <b-th style="width: 30% !important;">翻译</b-th>
                <b-th style="width: 20% !important;white-space:nowrap;">客户/客户部门</b-th>
                <b-th style="width: 20% !important;">&nbsp;&nbsp;操作</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(translate,translate_index) in order_item_data.translate_list"
                :key="`translate-${translate_index}`"
              >
                <b-td style="width: 30% !important; height:100%;">{{translate.source_content}}</b-td>
                <b-td style="width: 30% !important; height:100%;">{{translate.target_content}}</b-td>
                <b-td style="width: 20% !important; height:100%;">
                  <b-badge variant="info">{{translate.customer}}</b-badge>
                  <b-badge
                    variant="info"
                    style="white-space: pre-wrap;margin-top:10px"
                  >{{translate.customer_dept}}</b-badge>
                </b-td>
                <b-td style="width:20%;position: relative;">
                  <b-button
                    variant="secondary"
                    @click="order_part_replace(translate)"
                    size="sm"
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                  >替换</b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="12" style="margin-top:10px;">
          <h3>预览效果：</h3>
          <div v-html="buildTreeNode(order_item_data.tree_nodes)"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
//import word_data from '../js/word.json';
import { GetTagListByCategory } from "../api/common/index";
import { mapState } from "vuex";
import translate from "../js/translate.js";
import Word from "../js/word.js";
import {
  build_html_nodes,
  extractTextByLanguage,
  customFilter
} from "../utils/index";
const reg_body = /<body[^>]*?>[\s\S]+?<\/body>/g;

export default {
  name: "office-word",
  created() {
    this.init_form_data()
      .then(() => {
        this.load_form_data = true;
      })
      .catch(() => {
        this.init_err_msg = "加载失败，请关闭插件，重新打开插件";
      });
    Word.ready()
      .then(() => {
        console.log("加载成功");
      })
      .catch(() => {
        console.log("加载失败");
      });
    /*
    window.Office.onReady((info) => {
      if (info.host === window.Office.HostType.Word) {
        console.log('ready');
      }
    });
    */
  },
  data() {
    return {
      space: [],
      order_item_index: 0,
      order_item_data: {
        src: "",
        html: "",
        translate: "",
        control: null,
        translate_list: [],
        html_nodes: [],
        tree_nodes: []
      },
      xp: [],
      order_items: [],
      order_words: [],
      order_pagedata: [],
      order_paragraphs: { items: [] },
      show_order: false,
      load_form_data: false,
      init_err_msg: "",
      form: {
        reget: 1,
        source_language: "",
        target_language: ""
      },
      languages: [],
      categorys: [],
      debug: process.env.VUE_APP_DEBUG == "1",
      doing: false,
      init: false,
      step_status: {
        1: "secondary",
        2: "info",
        3: "success",
        4: "danger"
      },
      step_status_name: {
        1: "待执行",
        2: "执行中",
        3: "执行成功",
        4: "执行失败"
      },
      step1: 1,
      step2: 1,
      step2_count: 30,
      step2_total: 110,
      step3: 1,
      step3_count: 30,
      step3_total: 110,
      step4: 1,

      show_list: true,
      item_index: 0,
      perPage: 20,
      currentPage: 1,
      fields: [
        {
          key: "status",
          label: "状态",
          thStyle: "width:100px;"
        },
        {
          key: "src",
          label: "原文",
          thStyle: "min-width:100px;"
        },
        {
          key: "translate",
          label: "翻译",
          thStyle: "min-width:100px;"
        },
        {
          key: "tool",
          label: "操作 ",
          thStyle: "width:100px;"
        }
      ],
      items: [],
      item_data: {
        src: "",
        html: "",
        translate: "",
        control: null,
        translate_list: [],
        html_nodes: [],
        tree_nodes: []
      }
    };
  },

  computed: {
    ...mapState("app", ["language", "category"]),
    rows() {
      return this.items.length;
    },
    total_page() {
      return Math.ceil(this.items.length / this.perPage);
    }
  },
  methods: {
    listAll() {
      if (this.space != "") {
        this.items = this.space;
      }
    },
    listYes() {
      this.space = this.items;
      this.items = this.items.filter(item => {
        return item.translate != "" || item.translate_list.length != 0;
      });
    },
    order_next(index) {
      let html_control = Word.get_paragraph_html_order(
        this.order_paragraphs.items[index]._Id
      );
      this.order_translate(index, html_control);
    },

    async order_find(order_i) {
      //获取所有段落
      Word.run(async () => {
        if (this.form.reget == "1" && !order_i) {
          this.order_pagedata = [];
          this.order_items = [];
          this.order_item_index = 0;
        }
        console.log("xxxxxx", order_i);
        if (!order_i) {
          order_i = 0;
          const res = await Word.get_paragraphs();

          this.order_paragraphs.items = res.items.map(p => {
            let sourceLanguage = [
              "Chinese-Taiwan",
              "Chinese-HongKong"
            ].includes(this.form.source_language)
              ? "ChineseTraditional"
              : this.form.source_language;

            let extractedTextFromText = extractTextByLanguage(p.text);
            if (extractedTextFromText[sourceLanguage]) {
              p.newtext = p.text;
            } else {
              p.newtext = "";
            }
            let extractedTextFromSrc = extractTextByLanguage(p.src);
            if (extractedTextFromSrc[sourceLanguage]) {
              p.newsrc = p.src;
            } else {
              p.newsrc = "";
            }

            return p;
          });
          this.order_paragraphs.items = customFilter(
            this.order_paragraphs.items,
            p => p.newtext != ""
          );

          let tags = [];
          for (let index = 0; index < this.categorys.length; index++) {
            const category = this.categorys[index];
            if (this.form[category.value]) {
              tags.push(this.form[category.value]);
            }
          }
          let words = await translate({
            source_language: this.form.source_language,
            target_language: this.form.target_language,
            tags: tags
          });
          this.order_words = words.words;
          this.init = true;
          this.show_order = true;
        }
        if (order_i || order_i == 0) {
          let html_control = await Word.get_paragraph_html_order(
            this.order_paragraphs.items[order_i]._Id
          );
          this.order_translate(order_i, html_control);
        }
      });
    },

    order_gotolist() {
      this.init = false;
      this.show_order = false;
    },

    //分段处理
    order_translate(order_i, html_control) {
      let $ = window.$;
      let matches_body = html_control.value.match(reg_body);
      let matches_body_html = "";

      if (matches_body) {
        matches_body_html = `<div${matches_body[0].substring(
          5,
          matches_body[0].length - 5
        )}div>`;
        matches_body_html = $(matches_body_html)
          .find(".WordSection1")
          .html();
        matches_body_html = matches_body_html.replaceAll(
          `<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>`,
          ""
        );
      }

      let $root = $(`<div>${matches_body_html}</div>`);
      let { result, result_node } = build_html_nodes($root.children());

      this.order_pagedata.push({
        id: order_i,
        status: 0,
        src: this.order_paragraphs.items[order_i].newtext,
        translate: "",
        translate_list: [],
        control: this.order_paragraphs.items[order_i],
        ori_html: html_control.value,
        html_control: html_control,
        html: matches_body_html,
        html_nodes: result,
        tree_nodes: result_node,
        $root: $root
      });

      let regalpha = /^\w+$/g;
      const element = this.order_pagedata[order_i];
      let text = element.src.toLowerCase();
      let translate_list = this.order_words.filter(w => {
        let source_content = w.source_content.toLowerCase();
        return (
          text.includes(source_content) &&
          (regalpha.test(w.source_content)
            ? new RegExp(`\\b${source_content}\\b`, "gi").test(text)
            : true)
        );
      });

      let translate_word =
        translate_list.find(w => w.source_content.toLowerCase() === text) || "";
      if (translate_word) {
        element.translate = translate_word.target_content;
      }
      element.translate_list = translate_list;

      let html_nodes = element.html_nodes;
      if (html_nodes.length > 0) {
        let node_text = html_nodes[0].text.toLowerCase();
        let node_translate_list = this.order_words.filter(w => {
          let source_content = w.source_content.toLowerCase();
          return (
            node_text.includes(source_content) &&
            (regalpha.test(w.source_content)
              ? new RegExp(`\\b${source_content}\\b`, "gi").test(node_text)
              : true)
          );
        });

        let node_translate_word =
          node_translate_list.find(
            w => w.source_content.toLowerCase() === text
          ) || "";
        if (node_translate_word) {
          html_nodes[0].translate = node_translate_word.target_content;
        }
      }

      const customer = this.categorys[0];
      const customer_dept = this.categorys[1];

      if (element.translate_list.length > 0) {
        element.translate_list.forEach(tag => {
          const tags = JSON.parse(tag.tag_json);
          if (tags.length > 0) {
            tags.forEach(t => {
              customer.tags.forEach(customerTag => {
                if (customerTag.value == t.value) {
                  tag.customer = t.name;
                }
              });
              customer_dept.tags.forEach(deptTag => {
                if (deptTag.value == t.value) {
                  tag.customer_dept = t.name;
                }
              });
            });
          }
        });
      }

      this.order_item_data.translate_list = element.translate_list;
      this.order_item_index = order_i;
      this.order_item_data.src = element.src;
      this.order_item_data.html = element.html || "";
      this.order_item_data.html_nodes = element.html_nodes;
      this.order_item_data.tree_nodes = element.tree_nodes;
      this.order_item_data.control = element.control;
    },
    alert(msg) {
      this.$bvToast.toast(msg, {
        title: "系统提示",
        autoHideDelay: 5000
      });
    },
    async init_form_data() {
      let languages = this.language;
      this.languages = [
        {
          text: "请选择...",
          value: ""
        },
        ...languages.platform_code_value.map(i => {
          return {
            text: i.name,
            value: i.value
          };
        })
      ];
      let categorys = this.category;
      // let categorys = await GetTagCategoryList();
      this.categorys = categorys.get_tag_category_list.map(i => {
        return {
          text: i.name,
          value: i.category_guid
        };
      });
      for (let index = 0; index < this.categorys.length; index++) {
        const category = this.categorys[index];
        this.form[category.value] = "";
        let tags = await GetTagListByCategory({
          category_guid: category.value
        });
        category.tags = [
          {
            text: "全部",
            value: ""
          },
          ...tags.get_tag_list_by_category.map(i => {
            return {
              text: i.tag_schema,
              value: i.tag_guid
            };
          })
        ];
      }
    },

    onSubmit(e) {
      const button = event.submitter;
      e.returnValue = false;
      if (button.id == "btn1") {
        this.getWordData();
      } else if (button.id == "btn2") {
        this.order_find();
      }
    },
    onReset() {
      this.form.target_language = "";
      this.form.source_language = "";
      for (let index = 0; index < this.categorys.length; index++) {
        const category = this.categorys[index];
        this.form[category.value] = "";
      }
    },
    gotoword() {
      Word.run(async () => {
        await Word.goto(this.item_data.control._Id);
      });
    },
    order_gotoword() {
      Word.run(async () => {
        await Word.goto(this.order_item_data.control._Id);
      });
    },
    replaceword() {
      Word.run(async () => {
        await Word.replace(
          this.item_data.control._Id,
          this.buildTreeNode(this.item_data.tree_nodes)
        );
      });
    },

    part_replace(t) {
  const s = t.source_content;
  const target = t.target_content;
  this.item_data.html_nodes.forEach(i => {
    if (i.text.includes(s)) {
      i.translate = i.text.replace(s, target);
    }
  });
},
    order_part_replace(t) {
      const s = t.source_content;
      const target = t.target_content;
 
      this.order_item_data.html_nodes.forEach(i => {
        if (i.text.includes(s)) {
          i.translate = i.text.replace(s, target);
        }
      });
    },

    order_replaceword() {
      Word.run(async () => {
        await Word.replace(
          this.order_item_data.control._Id,
          this.buildTreeNode(this.order_item_data.tree_nodes)
        );
      });
    },

    replaceAll() {
      Word.run(async () => {
        let items = this.items.filter(i => i.status == 1);
        let has_error = false;
        for (let index = 0; index < items.length; index++) {
          const item = items[index];
          try {
            await Word.replace(
              item.control._Id,
              this.buildTreeNode(item.tree_nodes)
            );
          } catch (error) {
            has_error = true;
          }
        }
        if (has_error) {
          this.alert("没有找到对应的段落，如果文档已经修改，请重新加载");
        }
      });
    },

    gotolist() {
      this.show_list = true;
    },
    save(b) {
      this.items[this.item_index].status =
        this.items[this.item_index].translate_list.filter(
          i => !i.target_content
        ).length == 0
          ? 1
          : 0;
      if (b === true) {
        this.edit(this.item_index + 1);
      }
    },
    order_save(b) {
      this.order_items[this.order_item_index].status =
        this.order_items[this.order_item_index].translate_list.filter(
          i => !i.target_content
        ).length == 0
          ? 1
          : 0;
      if (b === true) {
        this.order_translate(this.order_item_index + 1);
      }
    },

    savereplace() {
      this.items[this.item_index].status =
        this.items[this.item_index].translate_list.filter(
          i => !i.target_content
        ).length == 0
          ? 1
          : 0;
      this.replaceword();
    },

    order_savereplace() {
      this.order_items[this.order_item_index].status =
        this.order_items[this.order_item_index].translate_list.filter(
          i => !i.target_content
        ).length == 0
          ? 1
          : 0;
      this.order_replaceword();
    },
    edit(index) {
      this.show_list = false;
      this.item_index = index;
      this.item_data.src = this.items[index].src;
      this.item_data.html = this.items[index].html || "";
      this.item_data.html_nodes = this.items[index].html_nodes;
      this.item_data.tree_nodes = this.items[index].tree_nodes;
      this.item_data.translate_list = this.items[index].translate_list;
      this.item_data.control = this.items[index].control;
      this.item_data.customer = this.items[index].customer || "";
      this.item_data.customer_dept = this.items[index].customer_dept || "";
    },
    buildTreeNode(tree_nodes) {
      const _build = node => {
        if (node.children.length == 0) {
          return (
            node.begin +
            node.text_before +
            (node.translate || node.text) +
            node.text_after +
            node.end
          );
        } else {
          return (
            node.begin + node.children.map(i => _build(i)).join("") + node.end
          );
        }
      };
      return tree_nodes.map(i => _build(i)).join("");
    },
    changeSearch() {
      this.form.reget = "0";
      this.init = false;
    },
    getWordData() {
      this.doing = true;
      this.step1 = 1;
      this.step2 = 1;
      this.step3 = 1;
      this.step4 = 1;
      this.step2_count = 0;
      this.step2_total = 0;
      this.step3_count = 0;
      this.step3_total = 0;
      let $ = window.$;

      /*
      let controller = new AbortController();
      controller.abort();
      */
      //1，初始化word文档信息
      this.step1 = 2;

      let pagedata = [];

      Word.run(async () => {
        if (this.form.reget == "1") {
          //如果需要重新抓取文本
          let res = await Word.get_paragraphs();
          const paragraphs = res;
          let newP = { items: [] };

          newP.items = paragraphs.items.map(p => {
            const sourceLanguage = [
              "Chinese-Taiwan",
              "Chinese-HongKong"
            ].includes(this.form.source_language)
              ? "ChineseTraditional"
              : this.form.source_language;

            let extractedTextFromText = extractTextByLanguage(p.text);
            if (extractedTextFromText[sourceLanguage]) {
              p.newtext = p.text;
            } else {
              p.newtext = "";
            }
            let extractedTextFromSrc = extractTextByLanguage(p.src);
            if (extractedTextFromSrc[sourceLanguage]) {
              p.newsrc = p.src;
            } else {
              p.newsrc = "";
            }

            return p;
          });

          newP.items = customFilter(newP.items, p => p.newtext != "");

          //2，开始抓取文本
          this.step2 = 2;
          this.step2_total = newP.items.length;
          this.step3_total = newP.items.length;

          for (let i = 0; i < newP.items.length; i++) {
            const p = newP.items[i];
            let html_control = await Word.get_paragraph_html(p);
            let matches_body = html_control.value.match(reg_body);
            if (matches_body) {
              matches_body = matches_body[0];
              matches_body = `<div${matches_body.substring(
                5,
                matches_body.length - 5
              )}div>`;
              matches_body = $(matches_body)
                .find(".WordSection1")
                .html();
              matches_body = matches_body.replaceAll(
                `<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>`,
                ""
              );
            } else {
              matches_body = "";
            }

            let $root = $(`<div>${matches_body}</div>`);
            let { result, result_node } = build_html_nodes($root.children());

            pagedata.push({
              id: i,
              status: 0,
              src: newP.items[i].newtext,
              translate: "",
              translate_list: [],
              control: newP.items[i],
              ori_html: html_control.value,
              html_control: html_control,
              html: matches_body,
              html_nodes: result,
              tree_nodes: result_node,
              $root: $root
            });
            this.step2_count = pagedata.length;
          }
          this.step2 = 3;
        } else {
          pagedata = this.items;
          for (let index = 0; index < pagedata.length; index++) {
            pagedata[index].translate = "";
            pagedata[index].status = 0;
            pagedata[index].translate_list = [];
            pagedata[index].html_nodes.forEach(i => {
              i.translate = "";
            });
          }
        }

        //开始翻译
        this.step3 = 2;
        let tags = [];
        for (let index = 0; index < this.categorys.length; index++) {
          const category = this.categorys[index];
          if (this.form[category.value]) {
            tags.push(this.form[category.value]);
          }
        }
        let words = await translate({
          source_language: this.form.source_language,
          target_language: this.form.target_language,
          tags: tags
        });
        words = words.words;
        const customer = this.categorys[0];
        const customer_dept = this.categorys[1];
        let regalpha = /^\w+$/g;

        for (let index = 0; index < pagedata.length; index++) {
          const element = pagedata[index];

          let text = element.src;

          let translate_list = words.filter(w => {
            text = text.toLowerCase() || text;
            return text.includes(
              w.source_content.toLowerCase() || w.source_content
            );
          });

          translate_list = translate_list.filter(w => {
            if (regalpha.test(w.source_content)) {
              //不是中文
              return new RegExp(`\\b${w.source_content}\\b`, "gi").test(text);
            } else {
              //是中文
              return true;
            }
          });
          let translate_word =
            translate_list.find(w => w.source_content == text) || "";
          if (translate_word) {
            element.translate = translate_word.target_content;
          }
          element.translate_list = translate_list;

          let html_nodes = element.html_nodes;
          html_nodes.forEach(i => {
            let node_text = i.text;
            let node_translate_list = words.filter(w => {
              node_text = node_text.toLowerCase() || node_text;
              return node_text.includes(
                w.source_content.toLowerCase() || w.source_content
              );
            });
            node_translate_list = node_translate_list.filter(w => {
              if (regalpha.test(w.source_content)) {
                //不是中文
                return new RegExp(`\\b${w.source_content}\\b`, "gi").test(
                  node_text
                );
              } else {
                //是中文
                return true;
              }
            });
            let node_translate_word =
              node_translate_list.find(w => w.source_content == text) || "";
            if (node_translate_word) {
              i.translate = node_translate_word.target_content;
            }
          });

          this.step3_count += 1;
        }

        this.step3 = 3;

        this.items = pagedata;
        this.items.forEach(item => {
          if (item.translate_list.length > 0) {
            item.translate_list.forEach(tag => {
              const tags = JSON.parse(tag.tag_json);

              if (tags.length > 0) {
                tags.forEach(t => {
                  // Check against customer tags
                  customer.tags.forEach(customerTag => {
                    if (customerTag.value ==t.value) {
                      tag.customer = t.name;
                    }
                  });
                  // Check against customer department tags
                  customer_dept.tags.forEach(deptTag => {
                    if (deptTag.value == t.value) {
                      tag.customer_dept = t.name;
                    }
                  });
                });
              }
            });
          }
        });
        this.doing = false;
        this.init = true;
      });
    }
  }
};
</script>

<style scoped>
.task-process {
  display: flex;
  align-items: center;
}
.partTranslate {
  display: flex;
}
</style>
