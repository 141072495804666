export const build_html_nodes = $roots => {
  let $ = window.$;
  let result_node = [];
  let result = [];

  const _repeat = num => {
    let a = new Array(num);
    a.fill(" ");
    return a.join("");
  };

  const _build = $node => {
    let nodeName = $node[0].nodeName;
    let outer = $node[0].outerHTML;
    if (nodeName == "#text") {
      let text = $node[0].wholeText
        .replaceAll("\n", " ")
        .replaceAll("&nbsp;", " ");
      if (text.trim()) {
        let r_node = {
          begin: "",
          end: "",
          text: text.trim(),
          translate: "",
          text_before: _repeat(text.length - text.trimStart().length),
          text_after: _repeat(text.length - text.trimEnd().length),
          children: []
        };
        result.push(r_node);
        return r_node;
      } else {
        return {
          begin: "",
          end: "",
          text: text.trim(),
          translate: "",
          text_before: _repeat(text.length - text.trimStart().length),
          text_after: "",
          children: []
        };
      }
    } else if (outer.endsWith("/>")) {
      return {
        begin: outer,
        end: "",
        text: "",
        translate: "",
        text_before: "",
        text_after: "",
        children: []
      };
    } else if ($node[0].childNodes.length == 0) {
      outer = outer.replace($node.html(), "");
      let text = $node
        .text()
        .replaceAll("\n", " ")
        .replaceAll("&nbsp;", " ");
      let r_node = {
        begin: outer.substring(0, outer.length - nodeName.length - 3),
        end: outer.substring(outer.length - nodeName.length - 3),
        text: text.trim(),
        translate: "",
        text_before: _repeat(text.length - text.trimStart().length),
        text_after: _repeat(text.length - text.trimEnd().length),
        children: []
      };
      result.push(r_node);
      return r_node;
    } else {
      outer = outer.replace($node.html(), "");
      let node_data = {
        begin: outer.substring(0, outer.length - nodeName.length - 3),
        end: outer.substring(outer.length - nodeName.length - 3),
        text: "",
        translate: "",
        text_before: "",
        text_after: "",
        children: []
      };
      for (let index = 0; index < $node[0].childNodes.length; index++) {
        const cnode = $node[0].childNodes[index];
        node_data.children.push(_build($(cnode)));
      }
      return node_data;
    }
  };
  for (let index = 0; index < $roots.length; index++) {
    const $root = $($roots[index]);
    result_node.push(_build($root));
  }
  return { result, result_node };
}

 export const extractTextByLanguage=(input)=> {
  const englishRegex = /[A-Za-z0-9\s.,!?'"()]+/g; // 英文字符（包括数字和常见标点符号）
  const spanishRegex = /[A-Za-z0-9\s.,!?'"()áéíóúüñÁÉÍÓÚÜÑ]+/g; // 西班牙语字符（包括特殊字符）
  const chineseSimplifiedRegex = /[\u4E00-\u9FFF]+/g; // 简体中文字符（Unicode范围）
  const japaneseRegex = /[\u3040-\u30FF\u31F0-\u31FF\uFF00-\uFFEF]+/g; // 日文字符（平假名、片假名、全角字符）
  const koreanRegex = /[\u1100-\u11FF\u3130-\u318F\uAC00-\uD7AF]+/g; // 韩文字符（韩文字母和音节块）
  const chineseTraditionalRegex = /[\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\u20000-\u2A6DF\u2A700-\u2B73F\u2B740-\u2B81F\u2B820-\u2CEAF\u2CEB0-\u2EBEF]+/g; // 繁体中文字符（Unicode范围）

  // 定义一个通用的函数来提取匹配的文本
  function extractText(regex) {
    const result = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      result.push(match[0]);
    }
    return result.join(" ");
  }

  return {
    English: extractText(englishRegex),
    Spanish: extractText(spanishRegex),
    Chinese: extractText(chineseSimplifiedRegex),
    Japanese: extractText(japaneseRegex),
    Korean: extractText(koreanRegex),
    ChineseTraditional: extractText(chineseTraditionalRegex)
  };
}

//过滤
export function customFilter(array, predicate) {
  const result = [];
  for (let i = 0; i < array.length; i++) {
    if (predicate(array[i])) {
      result.push(array[i]);
    }
  }
  return result;
}