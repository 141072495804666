import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import Vuex from 'vuex'
import './css/app.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex); 
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  store
}).$mount('#app');
