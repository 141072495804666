import { GetTagCategoryList,GetCodeValueList} from "../../api/common/index";

const state = {
  category:[],
  language:{}
}

const getters = {
  category: state => state.category,
  language: state => state.language
};

const actions = {
  async fetchCategory({ commit }) {
    try {
      const categorys = await GetTagCategoryList();
      commit('setCategory', categorys);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  },
  async fetchLanguage({ commit }) {
    try {
      const languages = await GetCodeValueList({ code_schema: "language" });
      console.log("languages", languages);
      commit('setLanguage', languages);
    } catch (error) {
      console.error("Failed to fetch languages:", error);
    }
  },

};

const mutations = {
  setCategory(state, categorys) {
    state.category = categorys;
  },
  setLanguage(state, languages) {
    state.language = languages;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};