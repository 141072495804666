import axios from 'axios';

const Axios = axios.create({
    responseType: 'json',
    withCredentials: false,
    baseURL:process.env.VUE_APP_BASEURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

Axios.interceptors.request.use((config) => {
    return config;
});


Axios.interceptors.response.use((res) => {
    if (typeof res.data === 'string') {
        return res.data;
    }
    if(res.data.IsError){
        const err = {
            statusCode: 200,
            errMsg: res.data.Message
        };
        return Promise.reject(err);
    }
    return res.data.Data;
}, (error) => {
    if (error.response) {
        const err = {
            statusCode: error.response.status,
            errMsg: `${error.request.status}-${error.request.statusText}`
        };
        return Promise.reject(err);
    } else {
        return Promise.reject(error);
    }
});

export default Axios;
