import ppt_data from '../js/ppt.json';
const is_need_translate = (value) => {
  if (!value) return false;
  if (!isNaN(value)) return false;
  return true;
};

const ppt_test = {
  ready() {
    return new Promise((resolve) => {
      resolve();
    });
  },
  run(fn) {
    fn();
  },
  async goto(id) {
    if (!id) throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
  },
  async replace(id, new_text) {
    if (!id) throw new Error('没有找到对应的段落，如果文档已经修改，请重新加载');
    else console.log(new_text);
  },
  async get_paragraphs() {
    return {
      items: ppt_data.filter(i => is_need_translate(i.src)).map((i, j) => {
        i.id = j;
        i.text = i.src;
        return i;
      })
    };
  },
  async get_paragraph_html(paragraph) {
    return {
      value: paragraph.ori_html
    };
  },
};

let ppt_context = null;

const ppt = {
  ready() {
    return new Promise((resolve) => {
      window.Office.onReady((info) => {
        if (info.host === window.Office.HostType.PowerPoint) {
          console.log('infohost', info.host);
          resolve();
        }
      });
    });
  },
  run(fn) {
    window.PowerPoint.run(async (context) => {
      ppt_context = context;
      console.log('ppt_context', ppt_context);
      await fn();
    })
  },

  // 获取文本
  async get_textRange() {
    const context = ppt_context;
    console.log('context', context);
    context.presentation.load("slides");
    await context.sync();

    const slides = context.presentation.slides;
    slides.load("items");
    await context.sync();

    const items = slides.items;
    const textContents = [];
    for (let i = 0; i < items.length; i++) {
      const slide = items[i];
      slide.load('shapes');
      await context.sync();

      const shapes = slide.shapes.items;
      for (let j = 0; j < shapes.length; j++) {
        const shape = shapes[j];
        shape.load('textFrame');
        await context.sync();
        if (shape.textFrame) {
          shape.textFrame.load('textRange');
          await context.sync();

          const textRange = shape.textFrame.textRange;
          textRange.load('text');
          await context.sync();
          const text = textRange.text;
          const font = textRange.font;
          font.load('bold, italic, color, size')
          await context.sync();
          textContents.push({
            text: text,
            fontSize: font.size,
            fontColor: font.color,
            bold: font.bold,
            italic: font.italic,
          });
        }
      }
    }
    return textContents;
  },


};


export default process.env.VUE_APP_DEBUG == '1' ? ppt_test : ppt;
