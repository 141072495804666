<template>
  <div id="app">
    <b-container>
      <b-row>
        <b-col md="auto">
          <br />
          <h2>版本：1.0.0</h2>
          <br />
        </b-col>
      </b-row>
    </b-container>
    <word v-if="is_word&&is_finish"></word>
    <excel v-if="is_excel&&is_finish"></excel>
    <ppt v-if="is_ppt&&is_finish"></ppt>
  </div>
</template>

<script>
//_host_Info=Word$Win32$16.01$zh-CN$$$$0
//_host_Info=Excel$Win32$16.01$zh-CN$$$$0
import word from "./pages/word.vue";
import excel from "./pages/excel.vue";
import ppt from "./pages/ppt.vue";

export default {
  name: "App",
  components: {
    word,
    excel,
    ppt
  },

  data() {
    let _host_Info = this.query("_host_Info").toLowerCase();
    return {
      is_word: _host_Info.includes("word"),
      is_excel: _host_Info.includes("excel"),
      is_ppt: _host_Info.includes("powerpoint"),
      is_finish: false
    };
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("app/fetchLanguage");
      await this.$store.dispatch("app/fetchCategory");
      this.is_finish = true;
    },
    query(name) {
      var queryString;
      if (arguments.length > 1) {
        queryString = arguments[1];
        var index = queryString.indexOf("?");
        if (index != -1) {
          queryString = queryString.substring(index + 1, queryString.length);
        } else {
          queryString = "";
        }
      } else {
        queryString = window.location.search.substr(1);
      }
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = queryString.match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style>
</style>
