<template>
  <div>
    <button @click="open_excel">打开Excel</button>
  </div>
</template>

<script>
import Excel from '../js/excel.js'

export default {
  name: 'office-excel',
  created() {
    Excel.ready().then(()=>{
      console.log('加载成功')
    }).catch(()=>{
      console.log('加载失败')
    });
  },
  data() {
    return {

    }
  },
  computed: {

  },
  methods:{
    open_excel(){
      Excel.run(async () => {
        let sheets = await Excel.get_sheets();
        await Excel.get_sheet_data(sheets[2].name);
        /*
        let sheets_data = [];
        
        for(let i=0;i<sheets.length;i++){
          let d = await Excel.get_sheet_data(sheets[i].name);
          sheets_data.push(d);
        }
          
        console.log(sheets_data);
        */
      });
    },
  }
}
</script>

<style scoped>

</style>
